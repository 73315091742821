<template>
  <v-simple-table fixed-header>
    <template v-slot:default>
      <thead class="v-data-table-header">
        <tr>
          <th
            role="columnheader"
            v-for="field in fields"
            :key="field.name"
            :style="{ width: field.width || 'auto' }"
          >
            {{ field.label }}
          </th>
        </tr>
        <filter-table :fields="fields"></filter-table>
      </thead>
      <tbody>
        <tr v-for="(item, index) in items" :key="`value_${index}`">
          <td
            v-for="field in fields"
            :key="`value_${index}_${field.name}`"
            v-html="item[field.name]"
          ></td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td :colspan="fields.length">
            <v-row no-gutters>
              <v-col cols="12" sm="11">
                <v-pagination
                  v-model="page"
                  :length="totalPage"
                  :total-visible="6"
                ></v-pagination>
              </v-col>
              <v-col cols="12" sm="1">
                <v-text-field
                  v-model.number="page"
                  outlined
                  dense
                  label="Trang"
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
          </td>
        </tr>
      </tfoot>
    </template>
  </v-simple-table>
</template>

<script>
export default {
  name: "CustomTable",
  components: {
    FilterTable: () => import("./FilterTable.vue"),
  },
  props: {
    isShowFilter: {
      type: Boolean,
      default: () => false,
    },
    fields: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    totalPage: {
      type: Number,
      default: () => 0,
    },
  },
  data: () => ({
    page: 1,
    filters: {},
    menu: null,
    dates: null,
  }),
};
</script>
