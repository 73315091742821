import moment from "moment";
import { httpClient } from "@/libs/http";

export const generateRandomString = (length = 10) => {
  let result = '';
  let characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
  let charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function debounce(fn, delay) {
  let timeoutID = null;
  return function () {
    clearTimeout(timeoutID);
    let args = arguments;
    let that = this;
    timeoutID = setTimeout(function () {
      fn.apply(that, args);
    }, delay);
  };
}

export function copyText(text) {
  navigator.clipboard.writeText(text)
    .then()
    .catch(err => {
      console.error('Error copying text: ', err);
    });
}

export function copyTextHasBr(element) {
  const $temp = document.createElement("textarea");
  const brRegex = /<br\s*[/]?>/gi;
  document.body.appendChild($temp);
  $temp.val(element.innerHTML.replace(brRegex, "\r\n")).select();
  document.execCommand("copy");
  $temp.remove();
}

export function checkUserLoginPermission(userPermissions, permissions) {
  if (!permissions || permissions.length === 0) {
    return true
  }
  if (typeof userPermissions === 'string') {
    userPermissions = [userPermissions]
  }
  if (userPermissions.includes("super_admin")) {
    return true
  }
  const checkPermission = userPermissions.filter(permission => permissions.includes(permission)) || []
  return checkPermission && checkPermission.length > 0
}

export function formatDateTime(time, format = 'HH:mm DD/MM/YYYY') {
  return moment(time).format(format)
}

export const isPhoneValid = (phone) => {
  // return /^(09|08|03|07|05|04)(\d{8})$/.test(phone) || /^(84)(\d{9})$/.test(phone)
  if (!phone) return false;
  if (!phone.trim()) return false;
  const re = /^(0[2-9][0-9]{8,9})$/;
  return re.test(phone);
}

export const isEmailValid = (email) => {
  return /\S+@\S+\.\S+/.test(email)
}

export const isMobile = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
}

export const defaultDataDate = ({ startDate, endDate, warehouses }) => {
  let minDate = null
  if (warehouses && warehouses.length === 1) {
    const warehouse = warehouses[0]
    if (warehouse.data_from) {
      minDate = moment(warehouse.data_from).format('YYYY-MM-DD')
      startDate = startDate < minDate ? minDate : startDate
      endDate = endDate < minDate ? minDate : endDate
    }
  }
  return {
    startDate, endDate, minDate
  }
}

export const formatNumber = (numb, fix = 2) => {
  if (!numb) numb = 0
  let fixPow = Math.pow(10, fix);
  numb = Math.round(numb * fixPow) / fixPow;
  return numb.toString().replace(/\d(?=(\d{3})+(?!\d))/g, '$&,');
}

export function getUrlParameter(sParam) {
  let sPageURL = window.location.search.substring(1),
    sURLVariables = sPageURL.split('&'),
    sParameterName,
    i;
  for (i = 0; i < sURLVariables.length; i++) {
    sParameterName = sURLVariables[i].split('=')
    if (sParameterName[0] === sParam) {
      return typeof sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1])
    }
  }
  return false;
}
export function isInputNumber(e) {
  e = (e) ? e : window.event;
  let charCode = (e.which) ? e.which : e.keyCode;
  if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46 && charCode !== 190) {
    e.preventDefault()
    e.stopPropagation()
    return false;
  } else {
    return true;
  }
}

export function capitalizeStr(str) {
  if (!str) {
    return str
  }
  const arr = str.split(" ");
  for (let i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }
  return arr.join(" ");
}

export function isInputInteger(e) {
  e = (e) ? e : window.event;
  let charCode = (e.which) ? e.which : e.keyCode;
  if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
    e.preventDefault()
    e.stopPropagation()
    return false;
  } else {
    return true;
  }
}

export function inputMaxlength(e) {
  if (e.target.value.length >= e.target.maxLength) {
    e.preventDefault()
    e.stopPropagation()
    return false
  }
  return true
}

export function hideString(str, len = 3) {
  if (!str) {
    return ''
  }
  str = `${str}`
  const strLen = str.length
  const starLen = strLen - len
  const strShow = str.slice(-len)
  const arr = []
  for (let i = 0; i < starLen; i++) {
    arr.push('*')
  }
  arr.push(strShow)
  return arr.join('')
}

export function replaceTxtStar(txt) {
  if (!txt || txt.length === 0) {
    return ''
  }
  if (!txt.includes('*')) {
    return txt
  }
  const txtSpl = txt.split('')
  const newTxt = []
  for (let i = 0; i < txtSpl.length; i++) {
    const t = txtSpl[i]
    const t_1 = txtSpl[i - 1]
    if (i === 0) {
      newTxt.push(t)
    } else {
      if (t === '*' && t_1 !== '*' || t !== '*') {
        newTxt.push(t)
      }
    }
  }
  return newTxt.join('')
}

export async function downloadExcelFile(url, data, filename = 'default.xlsx') {
  try {
    const res = await httpClient.post(url, data, {
      responseType: 'blob'
    })
    const blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = filename;
    link.click();
    URL.revokeObjectURL(link.href);
  } catch (e) {
    console.log('downloadExcelFile->ERROR: ', e)
    this.$vToastify.error(this.$t('messages.upload_failed'));
  }
}

export async function downloadPdfFile(url, /*data,*/ filename = 'default.xlsx') {
  try {
    /*const res = await httpClient.post(url, data, {
      responseType: 'blob'
    })
    const blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = filename;
    link.click();
    URL.revokeObjectURL(link.href);*/
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    link.click();
    URL.revokeObjectURL(link.href);
  } catch (e) {
    console.log('downloadExcelFile->ERROR: ', e)
    this.$vToastify.error(this.$t('messages.upload_failed'));
  }
}
